













import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  components: {
  },
})
export default class AppDownload extends Vue {

  get isH5() {
    return AppModule.isH5
  }
}
